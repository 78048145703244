import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "kt_modal_two_factor_authentication",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header flex-stack" }
const _hoisted_5 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body scroll-y pt-10 pb-15 px-lg-17" }
const _hoisted_8 = { class: "pb-10" }
const _hoisted_9 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5",
  for: "kt_modal_two_factor_authentication_option_1"
}
const _hoisted_10 = { class: "svg-icon svg-icon-4x me-4" }
const _hoisted_11 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center",
  for: "kt_modal_two_factor_authentication_option_2"
}
const _hoisted_12 = { class: "svg-icon svg-icon-4x me-4" }
const _hoisted_13 = { class: "notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6" }
const _hoisted_14 = { class: "svg-icon svg-icon-2tx svg-icon-warning me-4" }
const _hoisted_15 = { class: "mb-10 fv-row" }
const _hoisted_16 = { class: "fv-plugins-message-container" }
const _hoisted_17 = { class: "fv-help-block" }
const _hoisted_18 = { class: "d-flex flex-center" }
const _hoisted_19 = {
  ref: "submitAuthCodeButtonRef",
  type: "submit",
  "data-kt-element": "apps-submit",
  class: "btn btn-primary"
}
const _hoisted_20 = { class: "mb-10 fv-row" }
const _hoisted_21 = { class: "fv-plugins-message-container" }
const _hoisted_22 = { class: "fv-help-block" }
const _hoisted_23 = { class: "d-flex flex-center" }
const _hoisted_24 = {
  ref: "submitMobileButtonRef",
  type: "submit",
  "data-kt-element": "sms-submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[7] || (_cache[7] = _createElementVNode("h2", null, "Choose An Authentication Method", -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.state !== '' && 'd-none'])
          }, [
            _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-gray-400 fs-5 fw-bold mb-10" }, " In addition to your username and password, you’ll have to enter a code (delivered via app or SMS) to log into your account. ", -1)),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                class: "btn-check",
                name: "auth_option",
                value: "apps",
                checked: "checked",
                id: "kt_modal_two_factor_authentication_option_1",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
              }, null, 512), [
                [_vModelRadio, _ctx.value]
              ]),
              _createElementVNode("label", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/coding/cod001.svg" })
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("span", { class: "d-block fw-bold text-start" }, [
                  _createElementVNode("span", { class: "text-dark fw-bolder d-block fs-3" }, "Authenticator Apps"),
                  _createElementVNode("span", { class: "text-gray-400 fw-bold fs-6" }, " Get codes from an app like Google Authenticator, Microsoft Authenticator, Authy or 1Password. ")
                ], -1))
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                class: "btn-check",
                name: "auth_option",
                value: "sms",
                id: "kt_modal_two_factor_authentication_option_2",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event))
              }, null, 512), [
                [_vModelRadio, _ctx.value]
              ]),
              _createElementVNode("label", _hoisted_11, [
                _createElementVNode("span", _hoisted_12, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com003.svg" })
                ]),
                _cache[9] || (_cache[9] = _createElementVNode("span", { class: "d-block fw-bold text-start" }, [
                  _createElementVNode("span", { class: "text-dark fw-bolder d-block fs-3" }, "SMS"),
                  _createElementVNode("span", { class: "text-gray-400 fw-bold fs-6" }, "We will send a code via SMS if you need to use your backup login method.")
                ], -1))
              ])
            ]),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.state = _ctx.value)),
              class: "btn btn-primary w-100"
            }, " Continue ")
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.state !== 'apps' && 'd-none']),
            "data-kt-element": "apps"
          }, [
            _cache[13] || (_cache[13] = _createStaticVNode("<h3 class=\"text-dark fw-bolder mb-7\">Authenticator Apps</h3><div class=\"text-gray-500 fw-bold fs-6 mb-10\"> Using an authenticator app like <a href=\"https://support.google.com/accounts/answer/1066447?hl=en\" target=\"_blank\">Google Authenticator</a>, <a href=\"https://www.microsoft.com/en-us/account/authenticator\" target=\"_blank\">Microsoft Authenticator</a>, <a href=\"https://authy.com/download/\" target=\"_blank\">Authy</a>, or <a href=\"https://support.1password.com/one-time-passwords/\" target=\"_blank\">1Password</a>, scan the QR code. It will generate a 6 digit code for you to enter below. <div class=\"pt-5 text-center\"><img src=\"media/misc/qr.png\" alt=\"\" class=\"mw-150px\"></div></div>", 2)),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("span", _hoisted_14, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen044.svg" })
              ]),
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "d-flex flex-stack flex-grow-1" }, [
                _createElementVNode("div", { class: "fw-bold" }, [
                  _createElementVNode("div", { class: "fs-6 text-gray-600" }, [
                    _createTextVNode(" If you having trouble using the QR code, select manual entry on your app, and enter your username and the code: "),
                    _createElementVNode("div", { class: "fw-bolder text-dark pt-2" }, " KBSS3QDAAFUMCBY63YCKI5WSSVACUMPN ")
                  ])
                ])
              ], -1))
            ]),
            _createVNode(_component_Form, {
              class: "form",
              onSubmit: _cache[4] || (_cache[4] = ($event: any) => (_ctx.submitAuthCodeForm())),
              "validation-schema": _ctx.schema2
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_Field, {
                    type: "text",
                    class: "form-control form-control-lg form-control-solid",
                    placeholder: "Enter authentication code",
                    name: "code"
                  }),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_ErrorMessage, { name: "code" })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("button", {
                    type: "reset",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.state = '')),
                    class: "btn btn-white me-3"
                  }, " Cancel "),
                  _createElementVNode("button", _hoisted_19, _cache[12] || (_cache[12] = [
                    _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1),
                    _createElementVNode("span", { class: "indicator-progress" }, [
                      _createTextVNode(" Please wait... "),
                      _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                    ], -1)
                  ]), 512)
                ])
              ]),
              _: 1
            }, 8, ["validation-schema"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.state !== 'sms' && 'd-none']),
            "data-kt-element": "sms"
          }, [
            _cache[15] || (_cache[15] = _createElementVNode("h3", { class: "text-dark fw-bolder fs-3 mb-5" }, " SMS: Verify Your Mobile Number ", -1)),
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "text-gray-400 fw-bold mb-10" }, " Enter your mobile phone number with country code and we will send you a verification code upon request. ", -1)),
            _createVNode(_component_Form, {
              class: "form",
              onSubmit: _cache[6] || (_cache[6] = ($event: any) => (_ctx.submitMobileForm())),
              "validation-schema": _ctx.schema1
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_Field, {
                    type: "text",
                    class: "form-control form-control-lg form-control-solid",
                    placeholder: "Mobile number with country code...",
                    name: "mobile"
                  }),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_ErrorMessage, { name: "mobile" })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("button", {
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.state = '')),
                    class: "btn btn-white me-3"
                  }, " Cancel "),
                  _createElementVNode("button", _hoisted_24, _cache[14] || (_cache[14] = [
                    _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1),
                    _createElementVNode("span", { class: "indicator-progress" }, [
                      _createTextVNode(" Please wait... "),
                      _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                    ], -1)
                  ]), 512)
                ])
              ]),
              _: 1
            }, 8, ["validation-schema"])
          ], 2)
        ])
      ])
    ])
  ]))
}